<template>
    <div class="wrap">
        <div class="header"></div>
        <section class="padding40">
            <product class="part1" @btnClick="handelClick">
                <template v-slot:headerTitle>
                    <span>基金智能投研平台</span>
                </template>
                <template v-slot:headerContent>
                    <span>
                        基金投研平台以提升投资绩效为唯一目标的，提供基金数据和投研成果的查询与展示、投研实时计算与分析工具、基金投资组合管理、投研流程管理、以及投后绩效与风险管理系统。为投资机构的研究员、投资经理提供以数据挖掘、量化建模、机器学习为特色的投研管理系统，为投资中台、销售支持、基金投顾提供市场权威的投研成果和基金组合投后分析报告。
                    </span>
                </template>
                <product-item v-for="(item, index) in part1Data" :key="index">
                    <template v-slot:title>
                        <span>{{ item.title }}</span>
                    </template>
                    <template v-slot:content>
                        <span>{{ item.content }}</span>
                    </template>
                </product-item>
            </product>
            <img :src="imgUrl1" alt="" class="img1">
        </section>

        <section class="bg-gray padding40">
            <img class="img2" :src="imgUrl2" alt="">
            <product class="part2" @btnClick="handelClick">
                <template v-slot:headerTitle>
                    <span>数智化投顾平台</span>
                </template>
                <template v-slot:headerContent>
                    <span>
                        为财富管理机构提供面向大众客户的线上的基金投研和投后服务，辅助人工投顾服务高净值客户，赋能投顾的线上与线下服务的工具和内容。产品形态包括iPad APP、H5、企业微信小程序，产品功能包括基金投研、AI账户诊断与资产配置、AI报告、财富规划。产品内容包括基金AI资讯、AI研报、AI投资者教育。
                    </span>
                </template>
                <product-item v-for="(item, index) in part2Data" :key="index">
                    <template v-slot:title>
                        <span>{{ item.title }}</span>
                    </template>
                    <template v-slot:content>
                        <span>{{ item.content }}</span>
                    </template>
                </product-item>
            </product>
        </section>

								<section class="padding40">
										<product class="part1" @btnClick="handelClick">
											<template v-slot:headerTitle>
												<span>个人理财工具</span>
											</template>
											<template v-slot:headerContent>
												<span>
													依托AI算法与多源数据融合，提供智能公募基金筛选工具，多种方式支持分析基金的表现。集成ETF专区，基于市场波动矩阵与行业轮动模型精准匹配赛道型产品。自选股AI诊断引擎实时解析股票风险。搭载金融聊天机器人，通过自然语言交互实现低门槛普惠金融。
												</span>
											</template>
											<product-item v-for="(item, index) in part3Data" :key="index">
													<template v-slot:title>
															<span>{{ item.title }}</span>
													</template>
													<template v-slot:content>
															<span>{{ item.content }}</span>
													</template>
											</product-item>
											<template v-slot:footer>
												<el-popover
													placement="top"
													width="120"
													v-model="visible"
													popper-class="popover-content">
													<div class="pop-content">
														<i class="el-icon-close" @click="visible = false"></i>
														<img src="../../assets/product/zjapp.png" alt="">
													</div>
													<el-button  slot="reference" type="primary" round>平台入口</el-button>
												</el-popover>

												<el-button type="primary" plain round @click="handelClick">预约演示</el-button>
											</template>
										</product>
										<img :src="imgUrl3" alt="" class="img3">
								</section>
        <!-- <section class="padding40">
            <product class="part3" @btnClick="handelClick">
                <template v-slot:headerTitle>
                    <span>智策移动端</span>
                </template>
                <template v-slot:headerContent>
                    <span>
                        智策移动端旨在帮助投顾更好的服务客户，投顾在智策PC端或移动端的内容可以轻松地分享给客户，与客户的沟通将更加轻松。
                    </span>
                </template>
                <product-item v-for="(item, index) in part3Data" :key="index">
                    <template v-slot:title>
                        <span>{{ item.title }}</span>
                    </template>
                    <template v-slot:content>
                        <span>{{ item.content }}</span>
                    </template>
                </product-item>
            </product>
            <img class="img3" src="../../assets/product/img3.png" alt="">
        </section> -->

        <ApplyForm id="jump"/>

        <!-- <el-dialog
        title="预约演示"
        top="30vh"
        width="400px"
        :visible="dialogVisible"
        @close="close">
            <div class="flex-row">
                <div style="font-size:52px"><svg-icon icon-class="phone"></svg-icon></div>
                <div style="margin-left:8px;"><p style="font-size: 14px">请拨打以下电话，我们会第一时间安排演示</p><p style="font-size: 18px">吴经理：136 1185 8287</p></div> 
            </div>
        </el-dialog> -->
    </div>
</template>

<script>
import Product from './componets/Product'
import ProductItem from './componets/Productitem'
import ApplyForm from '../../components/ApplyForm'

export default {
  components: { Product, ProductItem, ApplyForm },
  data() {
      return {
          part1Data: [
              {title: '基金筛选', content: "基金分类、指标筛选、因子打分、产品比较、计算分析、预测性评价，逐层构建基金池；提供入池、审批、维护、合规风控的流程管理。"},
              {title: '基金评价', content: "定量研究包含绩效指标、净值回归、估值表分析、交易特征、风格漂移、绩效归因、风险预测；以及在线尽调管理，包括尽调模板、报告编写和留档保存。"},
              {title: '组合管理', content: "战术资产配置和基金组合的收益-风险优化与风险预算优化，组合策略的收益回测、归因分析、持仓配置穿透，实盘模拟跟踪，以及投后的组合绩效归因和风险监控。"},
              {title: 'AI报告系统', content: "依托AI智能引擎打造自动化报告的系统支持全维度基金分析场景，基于估值表、母基金交易等多源数据，生成基金市场、基金池动态追踪、单产品穿透式诊断及组合策略回测报告，并支持个性化模块编辑，助力投研效率跃升与投资者服务智能化升级。"},
          ],
          part2Data: [
              {title: '投顾展业终端', content: "通过iPad移动端、企微即时服务、网页端三位一体协同，赋能投顾面对面服务全场景。系统深度整合驻场服务工具箱，支持实时生成客户账户诊断报告与资配建议，并可在客户见证下同步输出带弹性参数的资产配置推演方案，打造从沟通方案到落地的闭环服务链。"},
              {title: 'AI账户诊断与资产配置系统', content: "智能诊断实时解析客户账户画像，通过动态映射市场波动矩阵与风险收益云图，生成三级调仓方案：1）持仓单品智能替代方案，2）AI投顾最优组合推荐，3）个性化资产配置方案。系统基于客户行为轨迹与市场预期差进行多维度交叉验证，输出最适合的调仓建议。"},
              {title: '智能消息提醒系统', content: "实时智能提醒，构建投顾主动式管理习惯。系统实时捕捉持仓异动、策略信号、合规节点等关键业务触发点，智能预判待办清单优先级，自动生成客户跟进任务流与合规指令接收台账，通过多线程任务看板实现事中风险穿透，确保投顾服务全链路零遗漏响应。"},
              {title: 'AI基金资讯', content: "基于基金知识库和AI技术，提供基金资讯与专业解读相结合资讯内容和基金产品报告等。"},
          ],


          part3Data: [
              {title: '智能选基工具', content: "依托AI量化引擎打造全天候基金决策工具，集成六大智能模块：秒级穿透公募基金筛选器、四维动态基金对比、新发基金追踪、股票持仓智能映射选基、五星ETF贝塔捕捉系统、机会榜热力图预警。"},
              {title: 'ETF专区', content: "打造免选股投资专区，集成网格化筛选引擎、行业景气度轮动模型、跨境Beta追踪器等工具。支持匹配赛道型ETF组合，智能捕捉行业轮动拐点与海外市场联动机会，热点追踪器实时解析资金博弈轨迹。系统内置费率穿透诊断与机会榜动态评级，提供从宽基到另类资产的阶梯式配置方案，实现低门槛、低费率的指数化投资。"},
              {title: 'AI自选股诊断', content: "构建多智能体协作决策矩阵，通过AI分析师团队数字分身实现三重穿透：1）情报追踪Agent实时扫描舆情链与财报异常点，2）技术策略Agent解析量价异动图谱与筹码迁移轨迹，3）基本面Agent构建财务三维透视模型。触发研究员Agent团多空辩论引擎，生成带置信度指数的看涨看跌博弈树。最终由AI投资经理Agent融合多维因子，输出动态推演路径图与多空平衡配置建议，打造机构级个股诊断的智能投研会战平台。"},
              {title: '小智Agent', content: "微信多轮对话机器人（AIAgent）提供智能、便捷的对话式投资服务，只需通过微信聊天，即可享受专业级的资产管理与市场洞察。 1）实时查询股票、基金、债券等资产的基本信息与行情数据，投资动态一目了然。 AI解析市场走势，提供消息面、技术面、基本面观点，助您快速把握投资机会。2）自定义监控资产的价格异动、舆情热点，重要资讯第一时间推送至微信。 3）热门策略配置与管理，通过对话轻松配置热门策略，全程监控策略执行效果，省心更省力。4）全天候追踪全市场财经快讯，AI精准筛选总结高价值信息，告别信息过载。"},
          ],

										imgUrl1: process.env.VUE_APP_IMG_URL + '/website/index/ty.png',
										imgUrl2: process.env.VUE_APP_IMG_URL + '/website/index/tg.png',
										imgUrl3: process.env.VUE_APP_IMG_URL + '/website/index/lc.png',
          dialogVisible: false,
										visible: false
      }
  },
  methods:{
      handelClick() {
        //   this.dialogVisible = true
        window.location.hash = "#jump"
      },
    //   close() {
    //       this.dialogVisible = false
    //   }
  }

}
</script>

<style lang="less" scoped>
.header{
    width:100%;
    height: 320px;
    background: url('../../assets/banner/product.png') no-repeat center;
    background-size: cover;
}
section{
    display: flex;
    justify-content: center;
    margin-top: 83px;
}
.padding40{
    margin-top: 43px;
    padding: 40px 0;
}
.img1{
    width: 522px;
    height: 551px;
    margin: 10px 40px;
    /* background-color: aqua; */
}
.img2{
    width: 522px;
    height: 554px;
    margin: 10px 40px;
    /* background-color: aqua; */
}
.img3{
    width: 582px;
    height: 484px;
    margin: 10px 40px;
    /* background-color: aqua; */
}
.bg-gray{
    background: #fafafa;
}
.flex-row{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    color: #666;
    line-height: 28px;
    padding: 40px 16px 48px;

}
/deep/ .el-dialog__header{
    background:#4E7CEE;
    padding: 23px;
}
/deep/ .el-dialog__title{
    color: #fff;
    font-size: 18px;
    font-weight: 400;
}
/deep/ .el-dialog__close.el-icon.el-icon-close{
    display: none;
}
.pop-content{
	position: relative;
	text-align: center;
	img{
		width: 110px;
		height: 110px;
		margin: 0 auto;
	}
	.el-icon-close{
		position: absolute;
		right: -5px;
		top: -5px;
		font-size: 12px;
		color: #999999;
		cursor: pointer;
	}
}
.el-button{
	background-color: #2E5082;
	border: #2E5082;
	width: 200px;
	margin-right: 20px;
}
.el-button--primary.is-plain{
	background: #ffffff;
	color: #2E5082;
	border:1px solid #2E5082;
}
</style>

<style>
.popover-content{
	min-width: 130px;
}
</style>