<template>
    <div class="wrap">
        <header>
            <div class="border"></div>
            <div class="header-title">
                <slot name="headerTitle"></slot>
            </div>
            <div class="header-content">
                <slot name="headerContent"></slot>
                <el-divider></el-divider>
            </div>
        </header>
        <main>
            <slot></slot>
        </main>
        <footer>
           <slot name="footer">
													<el-button type="primary" round @click="handleJump">平台入口</el-button>
													<el-button type="primary" plain round @click="handelClick">预约演示</el-button>
											</slot>
        </footer>
    </div>
</template>

<script>
export default {
    methods: {
        handelClick() {
            this.$emit('btnClick')
        },
								handleJump() {
											window.open(process.env.VUE_APP_LOGIN_URL)
								}
    }
}
</script>

<style scoped>
.border{
    width:130px;
    height:8px;
    background:#2E5082;
}
.header-title{
    font-size: 36px;
    font-weight: bold;
    text-align: left;
    margin-top: 10px;
}
.header-content{
    width: 630px;
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
}
footer{
    margin-top: 30px;
    width: 420px;
				display: flex;
				align-items: center;
}
.el-button{
    background-color: #2E5082;
    border: #2E5082;
    width: 200px;
				margin-right: 20px;
}
.el-button--primary.is-plain{
				background: #ffffff;
				color: #2E5082;
				border:1px solid #2E5082;
}
</style>